<template>
  <div class="searchbyskill">
    <div class="title-bar">
      <h1>Search By Skill</h1>
    </div>
    <div class="page-body">
      <div class="search-box">
        <v-autocomplete
          multiple
          chips
          clearable
          deletable-chips
          class="mx-3"
          hide-details
          solo
          text
          :items="skills"
          :no-data-text="noSkillsFoundText"
          :loading="isLoadingSkills"
          :item-text="'name'"
          :item-value="'id'"
          :color="colors.PRIMARY"
          :label="'Enter skills to search for'"
          v-on:change="getSearchResults"
          v-model="selectedSkills"
        ></v-autocomplete>
      </div>


      <div class="search-results" :class="{ 'empty-list': searchResults.length === 0 }">
        <v-card-text v-if="searchResults.length === 0 && !isLoadingResults" class="no-results-text">{{ noResultsText }}</v-card-text>

        <v-progress-circular
          v-if="isLoadingResults"
          class="searching-indicator"
          indeterminate
          :color="colors.PRIMARY"
        ></v-progress-circular>



        <v-expansion-panels class="result-container">
          <template v-for="item in fullMatchResults">
            <EmployeeResult :employeeProficiencies="item" :key="item.employeeEmail" />
          </template>
        </v-expansion-panels>

        <v-divider v-if="fullMatchResults.length !== 0"></v-divider>
        
        <p
          :color="colors.PRIMARY"
          class="text-button"
          :style="{'color': colors.PRIMARY}"
          v-on:click="partialMatchesShown = !partialMatchesShown"
          v-if="partialMatchResults.length !== 0 && !isLoadingResults"
        >{{ togglePartialMatchesText }}</p>
        
        <v-divider v-if="partialMatchResults.length !== 0"></v-divider>

        <v-expansion-panels v-if="partialMatchesShown" class="result-container">
          <template  v-for="item in partialMatchResults">
            <EmployeeResult :employeeProficiencies="item" :key="item.employeeEmail" />
          </template>          
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import colors from "@/colors";
import skillDataAccess from "@/dataAccess/skillDataAccess";
import proficiencyDataAccess from "@/dataAccess/proficiencyDataAccess";
import EmployeeResult from "./Components/EmployeeResult";

export default {
  name: "searchbyskill",
  components: {
    EmployeeResult
  },
  data() {
    return {
      colors,
      skills: [],
      selectedSkills: [],
      searchResults: [],
      partialMatchesShown: false,
      hasSearched: false,
      isLoadingSkills: true,
      tickLables: [1, 2, 3],
      isLoadingResults: false,
      previousSearch: [],
      skillDAO: new skillDataAccess(),
      proficiencyDAO: new proficiencyDataAccess()
    };
  },
  mounted: async function() {
    await this.initSkills();
  },
  methods: {
    async initSkills() {
      this.skills = await this.skillDAO.getAllSkills();
      this.skills.sort((skill1, skill2) => {
        var skill1Name = skill1.name.toUpperCase();
        var skill2Name = skill2.name.toUpperCase();
        return (skill1Name < skill2Name) ? -1 : (skill1Name > skill2Name) ? 1 : 0;
      });
      this.isLoadingSkills = false;
    },
    async getSearchResults() {
      this.isLoadingResults = true;
      this.hasSearched = true;
      this.partialMatchesShown = false;

      if (this.selectedSkills.length === 0) {
        this.isLoadingResults = false;
        this.searchResults = [];
        return;
      }

      if (this.selectedSkills.length < this.previousSearch.length) {
        this.searchResults = this.getReducedSearchResults();
      } else {
        this.searchResults = await this.getApiSearchResults();
      }

      this.searchResults.forEach(proficiencyGroup => {
        let score = 0;
        proficiencyGroup.proficiencies.forEach(proficiency => {
          score += proficiency.level;
        });
        proficiencyGroup.score = score;
      });

      this.searchResults.sort((prof1, prof2) => {
        return prof2.score - prof1.score;
      });

      this.previousSearch = this.selectedSkills;
      this.isLoadingResults = false;
    },
    getReducedSearchResults() {
      let removedSkillId = this.previousSearch.filter((skillId) =>  {
        return !this.selectedSkills.includes(skillId);
      })[0];

      let removedSkill = this.skills.find((skill) => {
        return skill.id === removedSkillId
      });

      this.searchResults.forEach((result) => {
        result.proficiencies = result.proficiencies.filter((prof) => {
          return prof.skillName !== removedSkill.name;
        });
      });

      let filteredResults = this.searchResults.filter((result) => {
        return result.proficiencies.length !== 0;
      });

      return filteredResults;
    },
    async getApiSearchResults() {
      return await this.proficiencyDAO.getProficienciesBySkills(
          this.selectedSkills
        );
    }
  },
  computed: {
    fullMatchResults() {
      return this.searchResults.filter(result => result.proficiencies.length === this.selectedSkills.length);
    },
    partialMatchResults() {
      return this.searchResults.filter(result => {
        return result.proficiencies.length !== this.selectedSkills.length;
      });
    },
    togglePartialMatchesText() {
      if (this.partialMatchesShown) {
        return "Hide partial matches";
      }
      return "Show partial matches";
    },
    noResultsText() {
      if (this.selectedSkills.length === 0) {
        return "Select skills to find employees";
      }
      return "No results";
    },
    
    noSkillsFoundText(){
      if(this.isLoadingSkills){
        return "Loading skills...";
      }
      return "No skills found";
    }
  }
};
</script>

<style scoped>
.page-body {
  max-width: 800px;
  margin: 15px;
  background-color: #fefefe;
  box-shadow: 0 1px 5px #aaaaaa;
}

.empty-list {
  min-height: 100px;
}

.search-box {
  box-shadow: 0px 1px 5px #aaaaaa;
  position: relative;
}

.no-results-text {
  color: #777777;
  margin-top: 45px;
}

.result-container {
  width: 100%;
}

.text-button {
  padding: 18px 0px;
  margin: 0;
  cursor: pointer;
}

.no-shadow {
  box-shadow: none;
}

.searching-indicator {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 800px) {
  .page-body {
    margin: 15px 0;
  }
}

</style>
