<template>
<v-expansion-panels>
  <v-expansion-panel class="no-shadow">
    <v-expansion-panel-header>
      <h2 class="employee-name">{{employeeProficiencies.employeeName}}</h2>
      <p
        :color="colors.PRIMARY"
        class="profile-button"
        v-on:click="navToUser(employeeProficiencies.employeeEmail)"
      >Profile</p>
    </v-expansion-panel-header>
    <v-expansion-panel-content>  
      <!-- <template v-slot:actions>
        <v-icon :color="colors.PRIMARY">$vuetify.icons.expand</v-icon>
      </template> -->
    
      <v-card v-for="proficiency in employeeProficiencies.proficiencies" :key="proficiency.id">
      <v-divider></v-divider>
        <div class="skill-info-container">
          <div class="skill-text-container">
            <span class="skill-info skill-title">{{proficiency.skillName}}</span>
            <span class="skill-info skill-date">Updated: {{proficiency.reviewedDate}}</span>
          </div>
          <span
            class="skill-level"
          >{{proficiency.level}} - {{skillLevelDescriptions[proficiency.level - 1]}}</span>
        </div>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</v-expansion-panels>
</template>

<script>
import colors from "@/colors";

export default {
  name: "employeeresult",
  props: ["employeeProficiencies"],
  data() {
    return {
      colors,
      skillLevelDescriptions: ["Basic", "Good", "Proficient"],
    };
  },
  methods: {
    navToUser(userEmail) {
      this.$router.push("/skills?user=" + userEmail);
    }
  }
};
</script>

<style scoped>
.skill-info-container {
  margin: 15px 22px;
  display: flex;
  align-items: center;
}

.skill-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  margin-right: 30px;
  margin-left: 5px;
  color: #777;
  border-right: 1px solid #999;
}

.skill-info {
  font-weight: 100;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}

.skill-info.skill-title {
  font-size: 12pt;
}

.skill-info.skill-date {
  font-size: 8pt;
  color: #afafaf;
}

.skill-level {
  font-size: 12pt;
  font-weight: 100;
  color: #777;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}

.employee-name {
  max-width: 300px;
  min-width: 300px;
}

.profile-button {
  margin: 0;
  color: #663399;
  cursor: pointer;
  padding: 0;
  margin-right: 50px;
  width: 50px;
  max-width: 50px;
  text-align: right;
  margin-left: auto;
}

@media screen and (max-width: 500px) {
  .employee-name {
    max-width: 200px;
    min-width: 200px;
  }
}

.no-shadow {
  box-shadow: none;
}

.result-container {
  width: 100%;
}
</style>


